import * as React from 'react';

const ADBE: React.FC = () => {
  return (
    <svg viewBox="0 0 174 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.098 58H9.089L12.627 47.996H27.206L30.622 58H39.162V55.133L24.339 15.3H15.86L1.098 55.133V58ZM15.189 40.798L19.947 26.89L24.583 40.798H15.189ZM45.6375 58H65.4015C73.2095 58 78.0285 54.035 78.0285 45.983C78.0285 40.066 75.1005 37.382 71.5625 35.857C74.7345 34.088 76.3205 31.221 76.3205 27.073C76.3205 19.265 71.4405 15.3 63.6935 15.3H45.6375V58ZM54.2995 33.051V22.864H63.2055C65.4625 22.864 67.5975 24.389 67.5975 27.927C67.5975 31.526 65.4625 33.051 63.2055 33.051H54.2995ZM54.2995 50.436V40.249H64.9745C67.2315 40.249 69.3665 41.774 69.3665 45.312C69.3665 48.911 67.2315 50.436 64.9745 50.436H54.2995ZM109.283 50.436V22.864H116.115C121.666 22.864 124.472 26.097 124.472 29.757V43.543C124.472 47.203 121.666 50.436 116.115 50.436H109.283ZM100.621 58H116.115C126.546 58 133.134 52.754 133.134 43.299V30.001C133.134 20.546 126.546 15.3 116.115 15.3H100.621V58ZM142.678 58H170.616V50.436H151.34V40.188H167.688V32.99H151.34V22.864H170.494V15.3H142.678V58Z"
        fill="currentColor"
      />
      <path
        d="M14.1544 103H16.5144L27.3544 75.8V75H25.1544L15.3544 99.92L5.47438 75H3.27438V75.8L14.1544 103ZM32.2463 103H34.5663V75H32.2463V103ZM42.2463 103H59.6463V100.92H44.5663V75H42.2463V103ZM64.5119 103H81.9119V100.92H66.8319V75H64.5119V103ZM86.7775 103H89.0975V75H86.7775V103ZM96.7775 103H113.818V100.92H99.0975V89.92H111.898V87.84H99.0975V77.08H113.778V75H96.7775V103ZM119.903 103H122.223V91.56H130.823L137.503 103H139.743V102.2L133.423 91.36C137.182 90.36 139.223 87.84 139.223 83.28C139.223 78.16 136.423 75 131.383 75H119.903V103ZM122.223 89.48V77.08H131.023C134.703 77.08 136.823 79.32 136.823 83.28C136.823 87.28 134.703 89.48 131.023 89.48H122.223ZM154.486 103.6C160.966 103.6 164.126 100.28 164.126 96.04C164.126 91.64 160.566 89.92 155.806 88.16L152.806 87.04C148.806 85.6 147.086 84.44 147.086 81.6C147.086 78.68 149.686 76.56 154.006 76.56C157.966 76.56 161.646 78.48 161.646 83.84H164.006C164.006 77.12 159.606 74.4 154.046 74.4C148.206 74.4 144.766 77.44 144.766 81.76C144.766 85.76 147.206 87.28 152.206 89.16L155.326 90.32C159.566 91.92 161.686 93.32 161.686 96.16C161.686 99.12 159.326 101.44 154.606 101.44C150.686 101.44 146.126 99.48 146.126 93.92H143.806C143.806 100.72 149.046 103.6 154.486 103.6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ADBE;
