import * as React from 'react';

const JoinIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 63 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.3757 2.88261C55.9897 2.53392 55.3984 2.56395 55.0497 2.94996L53.8157 4.31596C53.6363 4.51458 53.5576 4.76753 53.5761 5.01382L37.4008 22.9204C35.7105 21.619 34.2676 20.9417 33.8982 21.3505L1.77149 56.9157C1.30697 57.4299 2.71753 59.4556 4.93353 61.4573C7.14952 63.4591 9.30766 64.6571 9.77216 64.1429L41.8989 28.5777C42.2682 28.1688 41.4485 26.8014 39.9822 25.2522L56.1576 7.34567C56.4049 7.33866 56.6481 7.23521 56.8275 7.0366L58.0615 5.6706C58.4102 5.28459 58.3801 4.69325 57.9941 4.34456L56.3757 2.88261Z"
        fill="currentColor"
      />
      <path
        d="M5.07239 2.14054C5.86823 1.42164 7.09191 1.48379 7.81081 2.27964L9.04475 3.64564C9.29545 3.92318 9.45136 4.25416 9.5137 4.5974L24.8547 21.5804C25.4631 21.1577 26.0416 20.8149 26.5506 20.5837C26.9012 20.4244 27.2746 20.2919 27.6324 20.2478C27.953 20.2083 28.5377 20.2102 28.9623 20.6802L61.089 56.2454C61.3545 56.5394 61.4313 56.8872 61.4352 57.162C61.439 57.4281 61.3777 57.6789 61.3055 57.8877C61.1607 58.3062 60.9004 58.7568 60.5918 59.2001C59.965 60.1003 59.002 61.1634 57.8552 62.1994C56.7084 63.2353 55.5531 64.0856 54.5941 64.6179C54.1217 64.8801 53.6471 65.0934 53.2161 65.195C53.001 65.2457 52.7454 65.2813 52.481 65.2505C52.208 65.2187 51.8697 65.1072 51.6042 64.8132L19.4774 29.248C19.0529 28.7781 19.1102 28.1962 19.182 27.8813C19.2621 27.5298 19.4316 27.1716 19.6256 26.8389C19.9072 26.3559 20.3069 25.815 20.7891 25.2527L5.44794 8.26969C5.11345 8.17307 4.80001 7.98502 4.5488 7.70692L3.31486 6.34092C2.59596 5.54508 2.65812 4.32139 3.45396 3.6025L5.07239 2.14054Z"
        fill="currentColor"
        stroke="#081824"
        strokeWidth="2"
      />
      <path
        d="M27.7414 59.4912L27.9998 59.7497L28.1903 59.5592L27.8733 59.2423C27.9231 59.1554 27.9756 59.07 28.0321 58.9865L28.3881 59.3425L28.5786 59.1521L28.1977 58.7712L28.1607 58.8082C28.2408 58.7032 28.3263 58.6023 28.4171 58.5054L33.9851 64.0734C33.8835 64.1683 33.7778 64.2578 33.6682 64.3412L33.718 64.2914L33.3371 63.9105L33.1466 64.101L33.5039 64.4583C33.4205 64.5149 33.3351 64.5673 33.2482 64.6171L32.9481 64.317L32.7576 64.5075L32.9992 64.7491C32.4743 65.0008 31.8956 65.1347 31.296 65.1347C30.2435 65.1347 29.2543 64.7248 28.5101 63.9806C27.7658 63.2363 27.356 62.2471 27.356 61.1947C27.3565 60.595 27.4898 60.0162 27.7415 59.4913L27.7414 59.4912ZM28.7689 59.3432L28.5785 59.5337L28.9594 59.9145L29.1498 59.7241L28.7689 59.3432ZM29.3396 59.9138L29.1492 60.1043L29.53 60.4852L29.7205 60.2947L29.3396 59.9138ZM29.9109 60.4852L29.7205 60.6756L30.1013 61.0565L30.2918 60.8661L29.9109 60.4852ZM30.4816 61.0559L30.2911 61.2463L30.672 61.6272L30.8625 61.4367L30.4816 61.0559ZM31.053 61.6272L30.8625 61.8177L31.2434 62.1986L31.4338 62.0081L31.053 61.6272ZM31.6236 62.1978L31.4331 62.3883L31.8141 62.7692L32.0045 62.5787L31.6236 62.1978ZM32.1949 62.7692L32.0045 62.9597L32.3854 63.3405L32.5758 63.1501L32.1949 62.7692ZM32.956 63.9112L33.1465 63.7207L32.7656 63.3398L32.5752 63.5303L32.956 63.9112ZM32.5684 64.3176L32.7589 64.1272L32.378 63.7463L32.1875 63.9368L32.5684 64.3176ZM31.9971 63.747L32.1875 63.5565L31.8067 63.1757L31.6162 63.3661L31.9971 63.747ZM31.4258 63.1757L31.6163 62.9852L31.2354 62.6043L31.0449 62.7948L31.4258 63.1757ZM30.8551 62.605L31.0456 62.4145L30.6647 62.0336L30.4742 62.2241L30.8551 62.605ZM30.2837 62.0336L30.4742 61.8431L30.0933 61.4623L29.9029 61.6527L30.2837 62.0336ZM29.7131 61.463L29.9036 61.2725L29.5227 60.8916L29.3322 61.0821L29.7131 61.463ZM29.1418 60.8916L29.3322 60.7012L28.9514 60.3203L28.7609 60.5108L29.1418 60.8916ZM28.5711 60.3209L28.7615 60.1305L28.3807 59.7496L28.1902 59.9401L28.5711 60.3209Z"
        fill="currentColor"
      />
      <path
        d="M28.9104 58.0592L28.8444 58.1251L29.2253 58.506L29.4158 58.3155L29.0544 57.9542C29.1365 57.897 29.2206 57.8425 29.3068 57.792L29.6318 58.1171L29.8223 57.9266L29.5544 57.6588C30.0894 57.3943 30.6823 57.2537 31.2967 57.2537C32.3492 57.2537 33.3385 57.6635 34.0827 58.4078C34.8269 59.1527 35.2367 60.1419 35.2367 61.1945C35.2367 61.8089 35.096 62.4017 34.8316 62.9367L34.5806 62.6857L34.3901 62.8762L34.6983 63.1844C34.6479 63.2706 34.5934 63.3547 34.5362 63.4368L34.1734 63.074L33.983 63.2645L34.3638 63.6454L34.4433 63.566C34.3598 63.6757 34.2704 63.782 34.1754 63.8836L28.6074 58.3155C28.7051 58.2247 28.806 58.1393 28.9103 58.0592L28.9104 58.0592ZM30.0127 58.1177L29.8222 58.3082L30.2031 58.6891L30.3936 58.4986L30.0127 58.1177ZM30.584 58.6884L30.3935 58.8788L30.7744 59.2597L30.9649 59.0692L30.584 58.6884ZM31.1547 59.2597L30.9642 59.4502L31.3451 59.8311L31.5356 59.6406L31.1547 59.2597ZM31.726 59.8311L31.5356 60.0215L31.9164 60.4024L32.1069 60.2119L31.726 59.8311ZM32.2967 60.4017L32.1062 60.5922L32.4871 60.973L32.6775 60.7826L32.2967 60.4017ZM32.868 60.973L32.6775 61.1635L33.0584 61.5444L33.2489 61.3539L32.868 60.973ZM33.4387 61.5437L33.2482 61.7342L33.6291 62.1151L33.8196 61.9246L33.4387 61.5437ZM34.2004 62.6857L34.3909 62.4952L34.01 62.1143L33.8196 62.3048L34.2004 62.6857ZM33.7933 63.074L33.9838 62.8836L33.6029 62.5027L33.4124 62.6931L33.7933 63.074ZM33.2227 62.5027L33.4132 62.3122L33.0323 61.9313L32.8418 62.1218L33.2227 62.5027ZM32.6513 61.932L32.8418 61.7415L32.4609 61.3606L32.2705 61.5511L32.6513 61.932ZM32.08 61.3606L32.2705 61.1702L31.8896 60.7893L31.6998 60.9804L32.08 61.3606ZM31.5093 60.79L31.6998 60.5995L31.3189 60.2187L31.1284 60.4091L31.5093 60.79ZM30.938 60.2187L31.1285 60.0282L30.7476 59.6473L30.5571 59.8378L30.938 60.2187ZM30.3673 59.648L30.5578 59.4575L30.1769 59.0766L29.9864 59.2671L30.3673 59.648ZM29.796 59.0767L29.9864 58.8862L29.6055 58.5053L29.4151 58.6958L29.796 59.0767Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default JoinIcon;
